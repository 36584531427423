import React from "react";

export const RainfallData = () => {
  return (
    <div className="row mb-4">
      <div className="col-md-4">
        <div className="text-center">
          <div>1,115mm</div>
          <div>NORMAL RAINFALL</div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="text-center">
          <div>951mm</div>
          <div>ACTUAL RAINFALL</div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="text-center">
          <div>-15%</div>
          <div>% DEVIATION FROM NORMAL</div>
        </div>
      </div>
    </div>
  );
};

export default RainfallData;
