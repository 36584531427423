import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Filter = () => {
  const [filterData, setFilterData] = useState({
    timestampFilter: "monthly",
    startDate: "",
    endDate: "",
    aggregationType: "sum",
  });

  const { timestampFilter, startDate, endDate, aggregationType } = filterData;

  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    // Perform action with filterData
    console.log(filterData);
  };

  const onChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  return (
    <div className=" wrapper " style={{ marginTop: 1 }}> 
        <form className="rounded bg-body my-2" onSubmit={submitHandler}>
          <h2 className="mb-4">Filter</h2>

          {/* Filter Section */}
          <div className="mb-3">
            <label htmlFor="timestamp_filter" className="form-label">
              Timestep
            </label>
            <select
              id="timestamp_filter"
              className="form-select"
              name="timestampFilter"
              value={timestampFilter}
              onChange={onChange}
            >
              <option value="monthly">Monthly</option>
              <option value="daily">Daily</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="start_date" className="form-label">
              Start Date
            </label>
            <input
              type="date"
              id="start_date"
              className="form-control"
              name="startDate"
              value={startDate}
              onChange={onChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="end_date" className="form-label">
              End Date
            </label>
            <input
              type="date"
              id="end_date"
              className="form-control"
              name="endDate"
              value={endDate}
              onChange={onChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="aggregation_type" className="form-label">
              Aggregation Type
            </label>
            <select
              id="aggregation_type"
              className="form-select"
              name="aggregationType"
              value={aggregationType}
              onChange={onChange}
            >
              <option value="sum">Sum</option>
              <option value="avg">Average</option>
              <option value="min">Minimum</option>
              <option value="max">Maximum</option>
            </select>
          </div>

          {/* Submit Button */}
          <button
            id="filter_button"
            type="submit"
            className="btn w-100 py-2"
          >
            FILTER
          </button>
        </form>
      </div>
  );
};

export default Filter;
